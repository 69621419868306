<template>
  <div class="container">
    <div class="wrapper">
      <h1 class="h1">
        アカウント作成
      </h1>
      <p>CGBankの有料プランにご登録後、約10,000点の画像をご利用いただけます。</p>

      <p>
        有料プランのご利用を希望の方は、こちらからお進みください。
      </p>

      <div class="btn-container">
        <v-btn
          class="btn"
          color="#ed6484"
          href="/cgbank/artifacts"
        >
          新規アカウント作成
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  > .wrapper {
    position: fixed;
    z-index: 20;
    width: 40%;
    height: fit-content;
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: var(--white);

    @media (max-width: 768px) {
      // height: v-bind(contentHeight);
      border-radius: 0;
      padding: 1rem 0;
    }

    .btn-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
    }

    > .h1 {
      padding-bottom: 1rem;
    }
  }
}
</style>
